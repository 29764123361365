import React, { useContext } from 'react'
import { AppSidebar, AppFooter, AppHeader } from '../components/index'
import Loading from 'react-fullscreen-loading'
import Routing from './routing'
import { AppContext } from 'src/views/context/app'

const AppMainContainer = () => {
  const { loader } = useContext(AppContext)
  return (
    <div>
      <Loading loading={loader} background={'rgba(60, 60, 60, 0.6)'} loaderColor="#efefef" />
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3" style={{ background: '#EDF0FF' }}>
          <Routing />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default AppMainContainer
