import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Dashboard from 'src/views/dashboard/Dashboard'
import Offering from 'src/views/pages/offering/offering'
import MyOffering from 'src/views/pages/myOfferings/myOfferings'
import OfferingDetails from 'src/views/pages/offeringDetails/offeringDetails'
import AllUsers from 'src/views/pages/allUsers/allUsers'
import UserDetails from 'src/views/pages/userDetails/userDetails'
import WalletManagement from 'src/views/pages/walletManagement/walletManagement'
import WalletDetail from 'src/views/pages/walletDetail/walletDetail'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/offering', name: 'Offering', element: Offering },
  {
    path: '*',
    name: 'Dashboard',
    element: Dashboard,
  },
]

const Routing = () => {
  let accountId = localStorage.getItem('accountId')
  let token = localStorage.getItem('token')

  return (
    <>
      <Routes>
        {accountId && token ? (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="offerings-management" element={<MyOffering />} />
            <Route path="all-users" element={<AllUsers />} />
            <Route path="offering-details/:offeringId" element={<OfferingDetails />} />
            <Route path="user-details/:userId" element={<UserDetails />} />
            <Route path="wallet-management" element={<WalletManagement />} />
            <Route path="wallet-detail/:transactionId" element={<WalletDetail />} />
            <Route path="*" element={<Dashboard />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="login" replace />} />
        )}
      </Routes>
    </>
  )
}

export default React.memo(Routing)
