const initialState = {
  data: {},
  showAlert: false,
}

export const sweetAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SWEET_ALERT':
      return {
        ...state,
        data: action.payload,
        showAlert: action.show,
      }
    default:
      return state
  }
}
