import React, { useContext, useEffect, useState } from 'react'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import useAxios from 'axios-hooks'
import { AppContext } from '../../context/app'
import SearchTable from 'src/components/searchTable'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import StatusModal from 'src/components/statusModal/statusModal'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

const WalletManagement = () => {
  const { setLoader } = useContext(AppContext)
  const [selectedData, setSelectedData] = useState({})

  let accountId = localStorage.getItem('accountId')

  const [{ data: transactionList, loading: transactionListLoading }, transactionListCall] =
    useAxios(
      {
        url: `${ACCOUNT_URL}/${accountId}/escrow-transactions`,
        method: 'get',
      },
      { manual: true },
    )

  const [{ data: transactionUpdate, loading: transactionUpdateLoading }, transactionUpdateCall] =
    useAxios(
      {
        url: `${ACCOUNT_URL}/${accountId}/escrow-transactions`,
        method: 'patch',
      },
      { manual: true },
    )

  useEffect(() => {
    transactionListCall()
  }, [])

  useEffect(() => {
    if (transactionUpdate && transactionUpdate.success) {
      transactionListCall()
      setSelectedData({})
    }
  }, [transactionUpdate])

  useEffect(() => {
    setLoader(transactionListLoading || transactionUpdateLoading)
  }, [transactionListLoading, transactionUpdateLoading])

  const tableConfig = [
    {
      header: 'ID',
      dataCell: (info) => <Link to={`/wallet-detail/${info.id}`}>{info.id}</Link>,
      width: 20,
    },
    {
      header: 'Name',
      dataCell: (info) => `${info?.account?.firstName} ${info?.account?.lastName}`,
      width: 16,
    },
    {
      header: 'Amount (OMR)',
      dataCell: (info) => info.amount,
      width: 15,
    },
    {
      header: 'Status',
      dataCell: (info) => info.status,
      width: 13,
    },
    {
      header: 'Type',
      dataCell: (info) => info.type,
      width: 12,
    },
    {
      header: 'Date',
      dataCell: (info) => moment.utc(info.date).format('DD-MM-YYYY'),
      width: 12,
    },
    {
      header: <FontAwesomeIcon icon={faAngleDoubleDown} size="lg" className="mx-1" />,
      dataCell: (info) => (
        <CIcon
          icon={cilPencil}
          style={{ cursor: 'pointer' }}
          size="lg"
          title="Edit"
          onClick={() => {
            setSelectedData(info)
          }}
        />
      ),
      width: 7,
    },
  ]

  const handleUpdate = (status) => {
    transactionUpdateCall({
      url: `${ACCOUNT_URL}/${accountId}/escrow-transactions/${selectedData.id}`,
      data: { account: selectedData.account.id, status: status },
    })
  }

  return (
    <div className="mt-5 px-5">
      <h4 style={{ fontSize: '1.5625vw', fontWeight: '600' }} className="font-bold">
        Wallet
      </h4>
      <p style={{ fontSize: '1vw' }}>{`Manage Wallet`}</p>
      <SearchTable
        type={`Wallet`}
        tableCardHeight={65}
        marginDisable={false}
        disableButton={true}
        disableSearch={true}
        tableContent={transactionList}
        tableConfig={tableConfig}
      />

      {!isEmpty(selectedData) && (
        <StatusModal
          status={selectedData?.status}
          onSave={(value) => {
            handleUpdate(value)
          }}
          onCancel={() => {
            setSelectedData({})
          }}
        />
      )}
    </div>
  )
}

export default WalletManagement
