import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
const AlertComponent = () => {
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.sweetAlertReducer)
  const handleConfirm = () => {
    dispatch({
      type: 'SWEET_ALERT',
      payload: {},
      show: false,
    })
  }
  return (
    <>
      <SweetAlert
        type={`${data.type}`}
        title={`${data.title}`}
        onConfirm={handleConfirm}
        timeout={3000}
      >
        {data.message}
      </SweetAlert>
    </>
  )
}

export default AlertComponent
