/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'

const initialState = {
  userObj: {},
  isLogin: false,
  activePlans: [],
  userId: '',
}
//create context

export const AppContext = createContext(initialState)

//Provider Component

export const AppProvider = ({ children }) => {
  const [loader, setLoader] = useState(false)

  const [accountObj, setAccountObj] = useState({})

  return (
    <AppContext.Provider
      value={{
        setLoader,
        loader,
        accountObj,
        setAccountObj,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
