import React, { useEffect, useState } from 'react'
import {
  CButton,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'

const StatusModal = ({ status, showModal, onCancel, onSave }) => {
  const [statusValue, setStatusValue] = useState('')

  useEffect(() => {
    setStatusValue(status)
  }, [status])

  return (
    <CModal size="lg" visible={true} alignment="center">
      <CModalHeader className="px-4" closeButton={false}>
        <p style={{ fontSize: '1.2vw', fontWeight: '600' }}>{'Update Status'} </p>
      </CModalHeader>
      <CModalBody className="px-5 py-5">
        <CFormSelect
          value={statusValue}
          onChange={(e) => setStatusValue(e.target.value)}
          className=""
          size="sm"
        >
          {['PENDING', 'APPROVED', 'REJECTED'].map((info, index) => {
            return (
              <option value={info} key={index}>
                {info}
              </option>
            )
          })}
        </CFormSelect>
      </CModalBody>
      <CModalFooter>
        <CButton className="text-white mx-3 px-4" color="info" onClick={onCancel}>
          Cancel
        </CButton>
        <CButton className="text-white mx-3 px-4" color="info" onClick={() => onSave(statusValue)}>
          Save
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default StatusModal
