export const dispatchAlert = (type, title, message, show) => async (dispatch) => {
  dispatch({
    type: 'SWEET_ALERT',
    payload: {
      type: `${type}`,
      title: `${title}`,
      message: `${message}`,
    },
    show: show,
  })
}
