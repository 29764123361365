import React, { useContext, useEffect, useState } from 'react'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import useAxios from 'axios-hooks'
import { AppContext } from '../../context/app'
import SearchTable from 'src/components/searchTable'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import StatusModal from 'src/components/statusModal/statusModal'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

const MyOffering = () => {
  const { setLoader } = useContext(AppContext)
  const [selectedData, setSelectedData] = useState({})

  let accountId = localStorage.getItem('accountId')

  const [{ data: offeringList, loading: offeringListLoading }, offeringListCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/offerings`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: offeringUpdate, loading: offeringUpdateLoading }, offeringUpdateCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/offerings`,
      method: 'patch',
    },
    { manual: true },
  )

  useEffect(() => {
    offeringListCall()
  }, [])

  useEffect(() => {
    if (offeringUpdate && offeringUpdate.success) {
      offeringListCall()
      setSelectedData({})
    }
  }, [offeringUpdate])

  useEffect(() => {
    setLoader(offeringListLoading || offeringUpdateLoading)
  }, [offeringListLoading, offeringUpdateLoading])

  const tableConfig = [
    {
      header: 'Name',
      dataCell: (info) => <Link to={`/offering-details/${info.id}`}>{info.name}</Link>,
      width: 15,
    },
    {
      header: 'Capital Type',
      dataCell: (info) => info.capitalType,
      width: 20,
    },
    {
      header: 'Status',
      dataCell: (info) => info.status,
      width: 17,
    },
    {
      header: 'Price Per Share',
      dataCell: (info) => info.pricePerShare,
      width: 18,
    },
    {
      header: 'Close Date',
      dataCell: (info) => moment.utc(info.closeDate).format('DD-MM-YYYY'),
      width: 18,
    },
    {
      header: <FontAwesomeIcon icon={faAngleDoubleDown} size="lg" className="mx-1" />,
      dataCell: (info) => (
        <CIcon
          icon={cilPencil}
          style={{ cursor: 'pointer' }}
          size="lg"
          title="Edit"
          onClick={() => {
            setSelectedData(info)
          }}
        />
      ),
      width: 7,
    },
  ]

  const handleUpdate = (status) => {
    offeringUpdateCall({
      url: `${ACCOUNT_URL}/${accountId}/offerings/${selectedData.id}`,
      data: { status: status },
    })
  }

  return (
    <div className="mt-5 px-5">
      <h4 style={{ fontSize: '1.5625vw', fontWeight: '600' }} className="font-bold">
        Offerings
      </h4>
      <p style={{ fontSize: '1vw' }}>{`Manage Offerings`}</p>
      <SearchTable
        type={`Offering`}
        tableCardHeight={65}
        marginDisable={false}
        disableButton={true}
        disableSearch={true}
        tableContent={offeringList}
        tableConfig={tableConfig}
      />

      {!isEmpty(selectedData) && (
        <StatusModal
          status={selectedData?.status}
          onSave={(value) => {
            handleUpdate(value)
          }}
          onCancel={() => {
            setSelectedData({})
          }}
        />
      )}
    </div>
  )
}

export default MyOffering
