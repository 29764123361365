import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
// import LoginTopBar from '../components/loginTopBar/loginTopBar'
// import TextField from '../components/textField/textField'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoginTopBar from 'src/components/loginTopBar/loginTopBar'
import TextField from 'src/components/textField/textField'
import useAxios from 'axios-hooks'
import { LOGIN_ACCESS_TOKEN, ACCOUNT_URL } from 'src/views/utils/urls'
import { AppContext } from 'src/views/context/app'
import { GlobalContext } from 'src/views/context/GlobalState'
import { isEmpty } from 'src/views/utils/helper'

const Wrapper = styled.div`
  background: #fff;
  height: 100vh;
  .w54 {
    width: 54%;
  }
  .forgot-password {
    color: #211f9f;
    margin-left: auto;
  }
`

const FieldLabel = styled.div`
  input {
    height: 70px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #000000;
    font-size: 24px;
    font-weight: 700;
  }
`

const LoginButton = styled.button`
  color: #fff;
  background: #211f9f;
  border-radius: 10px;
  padding: 22px 170px;
  border: none;
`

const Login = () => {
  const { setLoader } = useContext(AppContext)
  const { updateLogin, userObj, signedIn } = useContext(GlobalContext)
  const navigate = useNavigate()
  const location = useLocation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    // getValues,
  } = useForm()

  const [{ data: loginData, loading: loginLoading }, loginCall] = useAxios(
    {
      url: LOGIN_ACCESS_TOKEN,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: accountData, loading: accountLoading }, accountCall] = useAxios(
    {
      url: ACCOUNT_URL,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (loginData && loginData.success) {
      localStorage.setItem('accountId', loginData.adminId)
      localStorage.setItem('token', loginData.token)
      accountCall({ url: `${ACCOUNT_URL}/${loginData.adminId}` })
      // updateLogin()
      // navigate('/')
    }
  }, [loginData])

  useEffect(() => {
    if (accountData && accountData.success) {
      updateLogin(accountData)
      // navigate('/')
    }
  }, [accountData])

  useEffect(() => {
    setLoader(loginLoading || accountLoading)
  }, [loginLoading, accountLoading])

  const onSubmit = (data) => {
    console.log(data)

    loginCall({
      data: {
        email: data.email,
        password: data.password,
      },
    })
  }

  useEffect(() => {
    console.log(signedIn, userObj)
    if (signedIn && !isEmpty(userObj)) {
      // debugger
      navigate('/')
      // const doesAnyHistoryEntryExist = location.key !== 'default'
      // if (doesAnyHistoryEntryExist) {
      //   navigate(-1)
      // } else {
      //   // no entry exists, we landed on the page via direct URL, there is no previous history so fallback to x
      // }
      // console.log('i am here')
      // let x = location?.state
      // debugger
      // // let x = location?.state
      // // console.log('i am here', x)
      // navigate(-1)
    }
  }, [signedIn, userObj])

  return (
    <Wrapper>
      <LoginTopBar />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="dFA fdC w54 mA">
          <p className="fs36 fwB mb32">ADMIN LOGIN</p>
          <FieldLabel className="fwB w100 mb64">
            <p className="fs36 mb16">EMAIL</p>
            <TextField
              id="email"
              label="EXAMPLE@GMAIL.COM"
              type="email"
              name="email"
              errors={errors}
              register={register}
              validationSchema={{
                required: 'Email is required',
                pattern: {
                  value: new RegExp(/^([A-Za-z0-9_\-.])+@\w{2,}\.\w{2,}(\.\w{2,})?$/),
                  message: 'Please enter a valid emial',
                },
              }}
              required
            />
          </FieldLabel>
          <FieldLabel className="fwB w100 mb64">
            <p className="fs36 mb16">PASSWORD</p>
            <TextField
              id="password"
              label="Password"
              type="password"
              name="password"
              errors={errors}
              register={register}
              validationSchema={{
                required: 'Password is required',
                pattern: {
                  value: new RegExp(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\d\s])[A-Za-z\d\W]{8,}$/,
                  ),
                  message: 'Please enter a valid password',
                },
              }}
              required
            />
          </FieldLabel>
          <LoginButton className="fs36 fwB" type="submit">
            LOGIN
          </LoginButton>
        </div>
      </form>
    </Wrapper>
  )
}

export default Login
