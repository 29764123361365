import React, { useContext, useEffect, useState } from 'react'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CRow, CCard } from '@coreui/react'
import useAxios from 'axios-hooks'
// import { raiseToast } from '../../utils/helper'
import { AppContext } from '../../context/app'
import SearchTable from 'src/components/searchTable'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { isEmpty } from 'lodash'
import StatusModal from 'src/components/statusModal/statusModal'
import { Link } from 'react-router-dom'

const AllUsers = () => {
  const { setLoader, accountObj } = useContext(AppContext)
  const [activePane, setActivePane] = useState(1)
  const [selectedData, setSelectedData] = useState({})
  let accountId = localStorage.getItem('accountId')
  const [{ data: accountsList, loading: accountsListLoading }, accountsListCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/accounts`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: accountsUpdate, loading: accountsUpdateLoading }, accountsUpdateCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/accounts`,
      method: 'patch',
    },
    { manual: true },
  )

  useEffect(() => {
    accountsListCall()
  }, [])

  useEffect(() => {
    if (accountsUpdate && accountsUpdate.success) {
      accountsListCall()
      setSelectedData({})
    }
  }, [accountsUpdate])

  useEffect(() => {
    setLoader(accountsListLoading || accountsUpdateLoading)
  }, [accountsListLoading, accountsUpdateLoading])

  const tableConfig = [
    {
      header: 'Name',
      dataCell: (info) => {
        return info.accountRole ? (
          <Link to={`/user-details/${info.id}`}>{`${info.firstName} ${info.lastName}`}</Link>
        ) : (
          `${info.firstName} ${info.lastName}`
        )
      },
      width: 20,
    },
    {
      header: 'Email',
      dataCell: (info) => info.email,
      width: 25,
    },
    {
      header: 'Status',
      dataCell: (info) => info.status,
      width: 20,
    },
    {
      header: 'Role',
      dataCell: (info) => info.accountRole,
      width: 23,
    },
    {
      header: <FontAwesomeIcon icon={faAngleDoubleDown} size="lg" className="mx-1" />,
      dataCell: (info) => (
        <CIcon
          icon={cilPencil}
          style={{ cursor: 'pointer' }}
          size="lg"
          title="Edit"
          onClick={() => {
            setSelectedData(info)
          }}
        />
      ),
      width: 7,
    },
  ]

  const handleUpdate = (status) => {
    accountsUpdateCall({
      url: `${ACCOUNT_URL}/${accountId}/accounts/${selectedData.id}`,
      data: { status: status },
    })
  }

  const activeTab = { color: '#1976d2', fontSize: '1.1rem', cursor: 'pointer' }
  const inActiveTab = { color: '#191818', fontSize: '1.1rem', cursor: 'pointer' }
  return (
    <div className="mt-5 px-5">
      <div className="font-bold px-0 w-100">
        <h4 style={{ fontSize: '1.5625vw', fontWeight: '600' }} className="font-bold px-0 w-100">
          Users
        </h4>
        <p style={{ fontSize: '1vw' }}>{`Manage Users`}</p>
      </div>
      <CNav variant="tabs" className="mt-2">
        <CNavItem>
          <CNavLink
            className="px-5"
            style={activePane === 1 ? activeTab : inActiveTab}
            active={activePane === 1}
            onClick={() => setActivePane(1)}
          >
            {`Fundraiser`}
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            className="px-5"
            style={activePane === 2 ? activeTab : inActiveTab}
            active={activePane === 2}
            onClick={() => setActivePane(2)}
          >
            Investor
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            className="px-5"
            style={activePane === 3 ? activeTab : inActiveTab}
            active={activePane === 3}
            onClick={() => setActivePane(3)}
          >
            All
          </CNavLink>
        </CNavItem>
      </CNav>

      <CTabContent className=" w-100">
        <CTabPane className=" w-100" role="tabpanel" visible={activePane === 1}>
          <CRow className="justify-content-center">
            <SearchTable
              type={`Offering`}
              tableCardHeight={65}
              marginDisable={true}
              disableButton={true}
              disableSearch={true}
              // tableContent={artistList && artistList.data}
              tableContent={
                accountsList && accountsList.filter((info) => info?.accountRole === 'FUNDRAISER')
              }
              tableConfig={tableConfig}
            />
          </CRow>
        </CTabPane>
        <CTabPane role="tabpanel" visible={activePane === 2}>
          <CRow className="justify-content-center">
            <SearchTable
              type={`Offering`}
              tableCardHeight={65}
              marginDisable={true}
              disableButton={true}
              disableSearch={true}
              tableContent={
                accountsList &&
                accountsList.filter(
                  (info) => info?.accountRole && info?.accountRole.includes('INVESTOR'),
                )
              }
              tableConfig={tableConfig}
            />
          </CRow>
        </CTabPane>
        <CTabPane role="tabpanel" visible={activePane === 3}>
          <CRow className="justify-content-center">
            <SearchTable
              type={`Offering`}
              tableCardHeight={65}
              marginDisable={true}
              disableButton={true}
              disableSearch={true}
              tableContent={accountsList}
              tableConfig={tableConfig}
            />
          </CRow>
        </CTabPane>
      </CTabContent>

      {!isEmpty(selectedData) && (
        <StatusModal
          status={selectedData?.status}
          onSave={(value) => {
            handleUpdate(value)
          }}
          onCancel={() => {
            setSelectedData({})
          }}
        />
      )}
    </div>
  )
}

export default AllUsers
