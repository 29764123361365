// TODO: IMPROVEMENT need to rearrange imports in alphabetical order in all file
import React, { useContext, useEffect, useState } from 'react'
import { CButton, CCard } from '@coreui/react'
import { styled } from 'styled-components'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { AppContext } from 'src/views/context/app'
import useAxios from 'axios-hooks'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { getImageUrl } from 'src/views/utils/helper'
import BasicPlaceholder from '../../../assets/basic-placeholder.jpeg'

const Wrapper = styled.div`
  padding: 32px 24px;
  .mb6 {
    margin-bottom: 6px;
  }
  /* TODO: need to set these colors in theme */
  .cBlue {
    color: #233e5f;
  }
  .cPurple {
    color: #5d3fd390;
  }
  .c333 {
    color: #333;
  }
  .cBlue {
    color: blue;
  }
`

const FundDetailCard = styled(CCard)`
  width: 80%;
  gap: 24px 150px;
  border-radius: 50px;
  padding: 36px 28px;
  margin-right: 45px;
  color: #fff;
  border: none;
  /* background: linear-gradient(to left, rgba(220, 131, 49, 0.6), rgba(92, 76, 115, 0.22)); */
  background: #fff;
  .columnElement {
    width: calc(100% / 2 - 24px / 2);
  }
`

const UserDetails = () => {
  let { userId } = useParams()
  const { setLoader } = useContext(AppContext)
  const [details, setDetails] = useState([])
  let accountId = localStorage.getItem('accountId')
  const navigate = useNavigate()

  const [{ data: accountsData, loading: accountsDataLoading }, accountsDataCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/accounts/${userId}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    accountsDataCall()
  }, [])

  useEffect(() => {
    if (accountsData && accountsData.success) {
      setDetails([{ ...accountsData }])
    }
  }, [accountsData])

  useEffect(() => {
    setLoader(accountsDataLoading)
  }, [accountsDataLoading])

  console.log({ details })

  return (
    <Wrapper>
      {/* <p className="fs20 fwSB mb64 cBlue">Offering Details </p> */}

      <div className="">
        {details.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <p className="fs36 fwB mb40">User Details</p>
              <div
                style={{ width: '80%' }}
                className="fs20 fwSB mb16 d-flex justify-content-between align-items-center"
              >
                <p>User Status Details</p>
                <CButton
                  onClick={() => navigate('/all-users')}
                  color="info"
                  className="text-white px-4"
                >
                  Back
                </CButton>
              </div>
              <FundDetailCard className="mb48 shadow-lg">
                <div className="fs20 mb16">
                  <p className="fs24 fwB cPurple mb6">Account Role</p>
                  <span className="c333">{item.accountRole || '-'}</span>
                </div>
                <div className="fs20 mb16">
                  <p className="fs24 fwB cPurple mb6">Status</p>
                  <span className="c333">{item.status || '-'}</span>
                </div>
              </FundDetailCard>
              <p className="fs20 fwSB mb16">Personal Details</p>
              <FundDetailCard className="mb48 shadow-lg">
                <div className="dF fwW">
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">First Name</p>
                      {item.firstName ? (
                        <span className="c333">
                          {item.accountRoleDetails.personalDetails.title.toUpperCase() || ''} $
                          {item.firstName}
                        </span>
                      ) : (
                        <span className="c333">-</span>
                      )}
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Email</p>
                      <span className="c333">{item.email || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Citizenship</p>
                      <span className="c333">
                        {item.accountRoleDetails.personalDetails.citizenship || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Contact Number</p>
                      <span className="c333">
                        {item.accountRoleDetails.personalDetails.contactNumber || '-'}
                      </span>
                    </div>
                  </div>
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Last Name</p>
                      <span className="c333">{item.lastName || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Country Of Residence</p>
                      <span className="c333">
                        {item.accountRoleDetails.personalDetails.countryOfResidence || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Date Of Birth</p>
                      <span className="c333">
                        {item.accountRoleDetails.personalDetails.dateOfBirth || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Country Code</p>
                      <span className="c333">
                        {item.accountRoleDetails.personalDetails.countryCode || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </FundDetailCard>
              <p className="fs20 fwSB mb16">Loction Details</p>
              <FundDetailCard className="mb48 shadow-lg">
                <div className="dF fwW">
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">country</p>
                      <span className="c333">{item.accountRoleDetails.details.country || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">State</p>
                      <span className="c333">{item.accountRoleDetails.details.state || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Street</p>
                      <span className="c333">{item.accountRoleDetails.details.street || '-'}</span>
                    </div>
                  </div>
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">City</p>
                      <span className="c333">{item.accountRoleDetails.details.city || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Building</p>
                      <span className="c333">
                        {item.accountRoleDetails.details.building || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </FundDetailCard>
              <p className="fs20 fwSB mb16">Company Details</p>
              <FundDetailCard className="mb48 shadow-lg">
                <div className="dF fwW">
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Name</p>
                      <span className="c333">
                        {item.accountRoleDetails.companyDetails.companyName || '-'}
                      </span>
                    </div>

                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Number</p>
                      <span className="c333">
                        {item.accountRoleDetails.companyDetails.companyNumber || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Website</p>
                      <span className="c333">
                        {item.accountRoleDetails.companyDetails.companyWebsite || '-'}
                      </span>
                    </div>
                  </div>
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Email</p>
                      <span className="c333">
                        {item.accountRoleDetails.companyDetails.companyEmail || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Contact Number</p>
                      <span className="c333">
                        {item.accountRoleDetails.companyDetails.contactNumber || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Country Code</p>
                      <span className="c333">
                        {item.accountRoleDetails.companyDetails.countryCode || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </FundDetailCard>
              <p className="fs20 fwSB mb16">Document Details</p>
              <FundDetailCard className="dF fwW fdR jcC shadow-lg">
                <div>
                  <p className="fs20 fwSB mb16 cPurple">Driving Liscense</p>
                  <div
                    style={{
                      width: '23.34375vw',
                      aspectRatio: 15 / 10,
                      background: '#828D9380',
                      overflow: 'hidden',
                      borderRadius: '20px',
                    }}
                    className="ml24 dFA jcC fdC mb12"
                  >
                    <img
                      className="w100 w-full"
                      src={getImageUrl(item.accountRoleDetails.documentsUrl.drivingLicense)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = BasicPlaceholder
                      }}
                      alt="thubnail img"
                    />
                  </div>
                  <p
                    className="dF jcFE cBlue cP"
                    onClick={() =>
                      window.open(
                        getImageUrl(item.accountRoleDetails.documentsUrl.drivingLicense),
                        '_blank',
                      )
                    }
                  >
                    <u>Download</u>
                  </p>
                </div>
                <div>
                  <p className="fs20 fwSB mb16 cPurple">International Address</p>
                  <div
                    style={{
                      width: '23.34375vw',
                      aspectRatio: 15 / 10,
                      background: '#828D9380',
                      overflow: 'hidden',
                      borderRadius: '20px',
                    }}
                    className="ml24 dFA jcC mb12"
                  >
                    <img
                      className="w100 w-full"
                      src={getImageUrl(item.accountRoleDetails.documentsUrl.internationalAddress)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = BasicPlaceholder
                      }}
                      alt="thubnail img"
                    />
                  </div>
                  <p
                    className="dF jcFE cBlue cP"
                    onClick={() =>
                      window.open(
                        getImageUrl(item.accountRoleDetails.documentsUrl.internationalAddress),
                        '_blank',
                      )
                    }
                  >
                    <u>Download</u>
                  </p>
                </div>
                <div>
                  <p className="fs20 fwSB mb16 cPurple">Passport</p>
                  <div
                    style={{
                      width: '23.34375vw',
                      aspectRatio: 15 / 10,
                      background: '#828D9380',
                      overflow: 'hidden',
                      borderRadius: '20px',
                    }}
                    className="ml24 dFA jcC"
                  >
                    <img
                      className="w100 w-full"
                      src={getImageUrl(item.accountRoleDetails.documentsUrl.passport)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = BasicPlaceholder
                      }}
                      alt="thubnail img"
                    />
                  </div>
                  <p
                    className="dF jcFE cBlue cP"
                    onClick={() =>
                      window.open(
                        getImageUrl(item.accountRoleDetails.documentsUrl.passport),
                        '_blank',
                      )
                    }
                  >
                    <u>Download</u>
                  </p>
                </div>
              </FundDetailCard>
            </React.Fragment>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default UserDetails
