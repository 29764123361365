/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import AppReducer from './AppReducer'
// import { validateSession } from '../../Redux/Actions/authAction'
// import { useDispatch } from 'react-redux'
import useAxios from 'axios-hooks'
import { isEmpty } from 'lodash'
import { ACCOUNT_URL } from '../utils/urls'
import { AppContext } from './app'

const initialState = {
  signedIn: false,
  userObj: {},
  userSessionObj: {},
}

export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)
  // const dispatchRedux = useDispatch()
  const { setAccountObj, setLoader } = useContext(AppContext)

  let accountId = localStorage.getItem('accountId')
  let token = localStorage.getItem('token')

  const validateUser = async () => {
    const validateRes = await accountCall({ url: `${ACCOUNT_URL}/${accountId}` })
    // debugger
    if (validateRes.status === 200 && validateRes?.data?.success) {
      updateLogin(validateRes.data)
    } else {
      logoutUser()
      localStorage.clear()
      window.reload()
    }
  }

  useEffect(() => {
    if (accountId) {
      validateUser()
    }
  }, [])

  const [{ loading: accountLoading }, accountCall] = useAxios(
    {
      url: ACCOUNT_URL,
      method: 'get',
    },
    { manual: true },
  )

  // useEffect(() => {
  //   if (accountData && accountData.success) {
  //     if (!language && !isEmpty(accountData?.data?.allowed_languages)) {
  //       localStorage.setItem('language', accountData?.data?.default_language)
  //     }
  //     localStorage.setItem('default_language', accountData?.data?.default_language)
  //     setAccountObj(accountData?.data)
  //   }
  // }, [accountData])

  useEffect(() => {
    setLoader(accountLoading)
  }, [accountLoading])

  // useEffect(() => {
  //   if (state.signedIn) {
  //     accountDataCall()
  //   }
  // }, [state.signedIn])

  function updateLogin(data) {
    dispatch({
      type: 'LOGIN',
      payload: data,
    })
  }
  function logoutUser() {
    dispatch({
      type: 'LOGOUT',
    })
  }
  return (
    <GlobalContext.Provider
      value={{
        signedIn: state.signedIn,
        updateLogin,
        logoutUser,
        userObj: state.userObj,
        userSessionObj: state.userSessionObj,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
