// TODO: IMPROVEMENT need to rearrange imports in alphabetical order in all file
import React, { useContext, useEffect, useState } from 'react'
import { CButton, CCard } from '@coreui/react'
import { styled } from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { getImageUrl } from 'src/views/utils/helper'
import BasicPlaceholder from '../../../assets/basic-placeholder.jpeg'
import moment from 'moment'
import { ACCOUNT_URL, EXPENSE_URL } from 'src/views/utils/urls'
import useAxios from 'axios-hooks'
import { AppContext } from 'src/views/context/app'
import { isEmpty } from 'lodash'

const Wrapper = styled.div`
  padding: 32px 24px;
  .mb6 {
    margin-bottom: 6px;
  }
  /* TODO: need to set these colors in theme */
  .cBlue {
    color: #233e5f;
  }
  .cPurple {
    color: #5d3fd390;
  }
  .c333 {
    color: #333;
  }
`

const FundDetailCard = styled(CCard)`
  width: 80%;
  gap: 24px
  border-radius: 50px;
  padding: 36px 28px;
  margin-right: 45px;
  color: #fff;
  border: none;
  background: #fff;
  .columnElement {
    width: calc(100% / 2 - 24px / 2);
  }
`

const WalletDetail = () => {
  let { transactionId } = useParams()
  const { setLoader } = useContext(AppContext)
  const [details, setDetails] = useState([])
  const [expenseDetails, setExpenseDetails] = useState({})
  let accountId = localStorage.getItem('accountId')
  const navigate = useNavigate()

  const [{ data: transactionData, loading: transactionDataLoading }, transactionDataCall] =
    useAxios(
      {
        url: `${ACCOUNT_URL}/${accountId}/escrow-transactions/${transactionId}`,
        method: 'get',
      },
      { manual: true },
    )

  const [{ data: expensesData, loading: expensesLoading }, expensesHistory] = useAxios(
    {
      url: EXPENSE_URL,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    if (transactionData && transactionData.success) {
      setDetails([{ ...transactionData }])
      if (transactionData.type === 'DEBIT') {
        expensesHistory({
          url: `${EXPENSE_URL}/${transactionData.expense}`,
        })
      }
    }
  }, [transactionData])

  useEffect(() => {
    if (expensesData && expensesData.success) {
      setExpenseDetails(expensesData)
    }
  }, [expensesData])

  useEffect(() => {
    setLoader(transactionDataLoading || expensesLoading)
  }, [transactionDataLoading, expensesLoading])

  useEffect(() => {
    if (transactionId) {
      transactionDataCall()
    }
  }, [transactionId])

  return (
    <Wrapper>
      {/* <p className="fs20 fwSB mb64 cBlue">Offering Details </p> */}

      <div className="">
        {details.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div
                style={{ width: '80%' }}
                className="fs20 fwSB mb16 d-flex justify-content-between align-items-center"
              >
                <p className="fs36 fwB mb40">Transaction Details</p>
                {/* <p>Transaction Status Details</p> */}
                <CButton
                  onClick={() => navigate('/wallet-management')}
                  color="info"
                  className="text-white px-4"
                >
                  Back
                </CButton>
              </div>
              <p className="fs20 fwSB mb16">Transaction Detailed Info</p>
              <FundDetailCard className="mb48 shadow-lg">
                <div className="dF fwW">
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Account Name</p>
                      <span className="c333">
                        {' '}
                        {`${item.account?.firstName} ${item.account?.lastName} ` || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Amount</p>
                      <span className="c333">{item.amount || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Date</p>
                      <span className="c333">
                        {moment.utc(item.date).format('DD-MM-YYYY') || '-'}
                      </span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Type</p>
                      <span className="c333">{item.type || '-'}</span>
                    </div>
                  </div>
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Email</p>
                      <span className="c333">{item?.account.email || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Transaction ID</p>
                      <span className="c333">{item.id || '-'}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Status</p>
                      <span className="c333">{item.status || '-'}</span>
                    </div>
                  </div>
                </div>
              </FundDetailCard>

              {item.type === 'DEBIT' && !isEmpty(expenseDetails) && (
                <>
                  <p className="fs20 fwSB mb16">Expense Detailed Info</p>
                  <FundDetailCard className="mb48 shadow-lg">
                    <div className="dF fwW">
                      <div className="columnElement">
                        <div className="fs20 mb16">
                          <p className="fs24 fwB cPurple mb6">Name</p>
                          <span className="c333">{`${expenseDetails?.name} ` || '-'}</span>
                        </div>
                        <div className="fs20 mb16">
                          <p className="fs24 fwB cPurple mb6">Account Number</p>
                          <span className="c333">{expenseDetails?.bankAccountNumber || '-'}</span>
                        </div>
                        <div className="fs20 mb16">
                          <p className="fs24 fwB cPurple mb6">Beneficiary Name</p>
                          <span className="c333">{expenseDetails?.bankBenificiaryName || '-'}</span>
                        </div>
                        <div className="fs20 mb16">
                          <p className="fs24 fwB cPurple mb6">Bank Branch</p>
                          <span className="c333">{expenseDetails?.bankBranch || '-'}</span>
                        </div>
                      </div>
                      <div className="columnElement">
                        <div className="fs20 mb16">
                          <p className="fs24 fwB cPurple mb6">Type</p>
                          <span className="c333">{expenseDetails.type || '-'}</span>
                        </div>
                        <div className="fs20 mb16">
                          <p className="fs24 fwB cPurple mb6">Bank Name</p>
                          <span className="c333">{expenseDetails.bankName || '-'}</span>
                        </div>
                        <div className="fs20 mb16">
                          <p className="fs24 fwB cPurple mb6">bankSwiftCode</p>
                          <span className="c333">{expenseDetails.bankSwiftCode || '-'}</span>
                        </div>
                      </div>
                    </div>
                  </FundDetailCard>
                </>
              )}
              <p className="fs20 fwSB mb16">Transaction Receipt</p>
              <FundDetailCard className="shadow-lg">
                <div
                  style={{
                    width: '27.34375vw',
                    aspectRatio: 15 / 10,
                    background: '#828D9380',
                    overflow: 'hidden',
                    borderRadius: '20px',
                  }}
                  className="ml24 dFA jcC mb32"
                >
                  <img
                    className="w100 w-full"
                    src={getImageUrl(item.transactionScreenshot)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = BasicPlaceholder
                    }}
                    alt="thubnail img"
                  />
                </div>
              </FundDetailCard>
            </React.Fragment>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default WalletDetail
