export default (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        signedIn: true,
        userObj: action.payload,
      }
    case 'LOGOUT':
      return {
        ...state,
        signedIn: false,
        userObj: {},
        userSessionObj: {},
      }
    default:
      return state
  }
}
