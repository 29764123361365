import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { configure } from 'axios-hooks'
import axios from 'axios'
import '../scss/style.scss'
import './App.css'
import Login from 'src/views/pages/login/Login'
import Register from 'src/views/pages/register/Register'
import Page404 from 'src/views/pages/page404/Page404'
import Page500 from 'src/views/pages/page500/Page500'
import { AppProvider } from 'src/views/context/app'
import { GlobalProvider } from 'src/views/context/GlobalState'
import AppMainContainer from './appMainContainer'
import { ToastContainer } from 'react-toastify'
import { AlertComponent } from '../components'
import { dispatchAlert } from '../Redux/Actions/authAction'

class App extends Component {
  render() {
    axios.interceptors.request.use(
      (req) => {
        let token = localStorage.getItem('token')
        if (token) {
          req.headers.Authorization = `Bearer ${token}`
        }
        return req
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error?.response?.status) {
          this.props.dispatchAlert(
            'danger',
            `Error ${error?.response?.status}`,
            `${error?.response?.data?.message}`,
            true,
          )
        }
        return Promise.reject(error)
      },
    )

    configure({ axios })
    return (
      <AppProvider>
        <GlobalProvider>
          <BrowserRouter>
            <ToastContainer />
            {this.props.showAlert && <AlertComponent />}
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<AppMainContainer />} />
            </Routes>
          </BrowserRouter>
        </GlobalProvider>
      </AppProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showAlert: state.sweetAlertReducer.showAlert,
    data: state.sweetAlertReducer.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ dispatchAlert }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
