import React, { useContext, useEffect, useState } from 'react'
import { CButton, CCard } from '@coreui/react'
import { styled } from 'styled-components'
import { useNavigate, useParams, Link } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { ACCOUNT_URL } from 'src/views/utils/urls'
import { AppContext } from 'src/views/context/app'

const Wrapper = styled.div`
  padding: 32px 24px;
  .mb6 {
    margin-bottom: 6px;
  }
  /* TODO: need to set these colors in theme */
  .cBlue {
    color: #233e5f;
  }
  .cPurple {
    color: #5d3fd390;
  }
  .c333 {
    color: #333;
  }
`

const FundDetailCard = styled(CCard)`
  width: 80%;
  gap: 24px
  border-radius: 50px;
  padding: 36px 28px;
  margin-right: 45px;
  color: #fff;
  border: none;
  background: #fff;
  .columnElement {
    width: calc(100% / 2 - 24px / 2);
  }
`

const SocialLink = styled(Link)`
  color: #00008b;
`

// const details = [
//   {
//     id: '6484cf6a34eaee25e00b6192',
//     account: '6460b0c2725dda6099c3051b',
//     owner: 'Manan',
//     contactPoint: 'test contact point',
//     name: 'test offering',
//     openDate: '1999-01-13',
//     closeDate: '1999-04-13',
//     capitalType: 'test capital type',
//     creditScore: '79',
//     sharesCount: '100000',
//     pricePerShare: '129',
//     fundingGoal: 'test funding goal',
//     liquidatingPercentage: '50',
//     maxCommitmentShares: '80000',
//     minCommitmentShares: '20000',
//     shortDescription: 'test short description',
//     longDescription: 'test long description',
//     imagePaths: ['abc/xyz', 'dir/tmp'],
//     socialUrls: ['sampleLink01.com', 'sampleLink02.com', 'sampleLink03.com', 'sampleLink04.com'],
//     success: true,
//   },
// ]

const OfferingSDetails = () => {
  let { offeringId } = useParams()
  const { setLoader } = useContext(AppContext)
  const [details, setDetails] = useState([])
  let accountId = localStorage.getItem('accountId')
  const navigate = useNavigate()

  const [{ data: offeringData, loading: offeringDataLoading }, offeringDataCall] = useAxios(
    {
      url: `${ACCOUNT_URL}/${accountId}/offerings/${offeringId}`,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    offeringDataCall()
  }, [])

  useEffect(() => {
    if (offeringData && offeringData.success) {
      setDetails([{ ...offeringData }])
    }
  }, [offeringData])

  useEffect(() => {
    setLoader(offeringDataLoading)
  }, [offeringDataLoading])

  return (
    <Wrapper>
      {/* <p className="fs20 fwSB mb64 cBlue">Offering Details </p> */}

      <div className="">
        {details.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div
                style={{ width: '80%' }}
                className="fs20 fwSB mb16 d-flex justify-content-between align-items-center"
              >
                <p className="">Offering Details</p>
                <CButton
                  onClick={() => navigate('/offerings-management')}
                  color="info"
                  className="text-white px-4"
                >
                  Back
                </CButton>
              </div>
              <FundDetailCard className="mb48 shadow-lg">
                <div>
                  <div className="dF fwW">
                    <div className="columnElement">
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">Owner</p>
                        <span className="c333">{item.owner}</span>
                      </div>
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">Open Date</p>
                        <span className="c333">{item.openDate}</span>
                      </div>
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">Contact Point</p>
                        <span className="c333">{item.contactPoint}</span>
                      </div>
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">Capital Type</p>
                        <span className="c333">{item.capitalType}</span>
                      </div>
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">shortDescription</p>
                        <span className="c333">{item.shortDescription}</span>
                      </div>
                    </div>
                    <div className="columnElement">
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">Name</p>
                        <span className="c333">{item.name}</span>
                      </div>
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">Close Date</p>
                        <span className="c333">{item.closeDate}</span>
                      </div>
                      <div className="fs20 mb16">
                        <p className="fs24 fwB cPurple mb6">Funding Goal</p>
                        <span className="c333">{item.fundingGoal}</span>
                      </div>
                    </div>
                  </div>
                  <div className="fs20 mb16">
                    <p className="fs24 fwB cPurple mb6">Long Description</p>
                    <span className="c333">{item.longDescription}</span>
                  </div>
                </div>
              </FundDetailCard>
              <p className="fs20 fwSB mb16">Financial Details</p>
              <FundDetailCard className="mb48 shadow-lg">
                <div className="dF fwW">
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Credit Score</p>
                      <span className="c333">{item.creditScore}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Max Commitment Shares</p>
                      <span className="c333">{item.maxCommitmentShares}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">shares Count</p>
                      <span className="c333">{item.sharesCount}</span>
                    </div>
                  </div>
                  <div className="columnElement">
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Liquidating Percentage</p>
                      <span className="c333">{item.liquidatingPercentage}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Min Commitment Shares</p>
                      <span className="c333">{item.minCommitmentShares}</span>
                    </div>
                    <div className="fs20 mb16">
                      <p className="fs24 fwB cPurple mb6">Price Per Share</p>
                      <span className="c333">{item.pricePerShare}</span>
                    </div>
                  </div>
                </div>
              </FundDetailCard>
              <p className="fs20 fwSB mb16">Social Details</p>
              <FundDetailCard className="shadow-lg">
                {item.socialUrls.map((urls, index) => {
                  console.log({ urls })
                  return (
                    <SocialLink className="mb16" key={index}>
                      <u>{urls}</u>
                    </SocialLink>
                  )
                })}
              </FundDetailCard>
            </React.Fragment>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default OfferingSDetails
