import { CButton, CCol, CFormInput, CFormSelect, CRow } from '@coreui/react'
import React from 'react'
import { styled } from 'styled-components'

const headingStyle = {
  fontSize: '1.1vw',
  fontWeight: '600',
  color: '#5C4C73',
}

const Wrapper = styled.div`
  padding: 32px 0;
  .mb6 {
    margin-bottom: 6px;
  }
`

const CancelButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #fff;
    border: 2px solid #233e5f;
  }
`

const NextButton = styled(CButton)`
  padding: 10px 35px;
  box-shadow: 0px, 6px rgba(0, 0, 0, 0.25);
  border: 2px solid #233e5f;
  background: #233e5f;
  border-radius: 4px;
  color: #233e5f;
  &:hover {
    background: #fff;
    border: 2px solid #233e5f;
  }
`

const Offering = () => {
  return (
    <Wrapper>
      <div>
        <p className="fs24 fwSB mx-3 mb24">Basic Details</p>
        <div className="mx-3">
          <CRow>
            <CCol>
              <p className="mb6" style={headingStyle}>
                SELECT OWNER*
              </p>
              <CFormSelect></CFormSelect>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CONTACT POINT
              </p>
              <CFormSelect></CFormSelect>
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                OFFERING NAME**
              </p>
              <CFormInput></CFormInput>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                ESTIMATES EPR %*
              </p>
              <CFormInput></CFormInput>
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                OPEN DATE
              </p>
              <CFormInput></CFormInput>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CLOSE DATE *
              </p>
              <CFormInput></CFormInput>
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                CAPITAL TYPE *
              </p>
              <CFormSelect></CFormSelect>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                CREDIT SCORE
              </p>
              <CFormSelect></CFormSelect>
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                NUMBER OF SHARES*
              </p>
              <CFormInput></CFormInput>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                PRICE PER SHARE, $
              </p>
              <CFormInput></CFormInput>
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol>
              <p className="mb6" style={headingStyle}>
                FUNDING GOAL, $
              </p>
              <CFormInput></CFormInput>
            </CCol>
            <CCol>
              <p className="mb6" style={headingStyle}>
                MAX COMMITMENT,SHARES
              </p>
              <CFormInput></CFormInput>
            </CCol>
          </CRow>
          <CRow className="my-4">
            <CCol lg="6">
              <p className="mb6" style={headingStyle}>
                MIN COMMITMENT,SHARES
              </p>
              <CFormInput></CFormInput>
            </CCol>
          </CRow>
        </div>
        {/* <p className="mx-3 my-3" style={{ fontSize: '24px', fontWeight: '600' }}>
            OFFERING DESCRIPTION
          </p> */}
        <div className="dF jcFE mx-3">
          <CancelButton
            className="fs16 fwSB baW mx-5 text-black"
            variant="outline"
            shape="rounded-pill"
          >
            CANCEL
          </CancelButton>
          <NextButton className="fs24 fwSB text-white" shape="rounded-pill">
            NEXT
          </NextButton>
        </div>
      </div>
    </Wrapper>
  )
}

export default Offering
