import React from 'react'
import styled from 'styled-components'
import FundtLogo from '../../assets/fundt-logo.svg'

const TopBar = styled.div`
  padding: 8px 50px;
  background: #f4f4f4;
  border-radius: 0px 0px 50px 50px;
  width: 90%;
  margin-bottom: 80px;
`

const LoginTopBar = () => {
  return (
    <TopBar className="d-flex justify-content-center align-items-center mA ">
      <img src={FundtLogo} alt="Fundt Logo" width="216" height="120" />
    </TopBar>
  )
}

export default LoginTopBar
